import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const channelRouter = {
  path: "/channel",
  component: Layout,
  redirect: "/channel/admin",
  meta: {
    icon: "insti-admin",
    title: $t("channel.channelTitle"),
    rank: 1,
    authority: ["acquirer.channel.page"]
  },
  children: [
    {
      path: "/channel/admin",
      name: "ChannelTitle",
      component: () => import("/@/views/channel-admin/ChannelAdmin/index.vue"),
      meta: {
        title: $t("channel.channelTitle"),
        authority: ["acquirer.channel.page"]
      }
    },
    {
      path: "/channel/detail",
      name: "ChannelDetail",
      component: () => import("/@/views/channel-admin/ChannelDetail/index.vue"),
      meta: {
        title: $t("channel.channelDetail"),
        showLink: false,
        authority: ["acquirer.channel.update"],
        activeMenu: "/channel/admin"
      }
    },
    {
      path: "/channel/add",
      name: "ChannelAdd",
      component: () => import("/@/views/channel-admin/ChannelAdd/index.vue"),
      meta: {
        title: $t("channel.channelAdd"),
        showLink: false,
        authority: ["acquirer.channel.update"],
        activeMenu: "/channel/admin"
      }
    }
  ]
};

export default channelRouter;
