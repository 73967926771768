import type { App } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist"; //解决刷新页面，缓存
const store = createPinia();
store.use(piniaPluginPersist);
export function setupStore(app: App<Element>) {
  app.use(store);
}

export { store };
