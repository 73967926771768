import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const terminalMgrRouter = {
  path: "/terminal-mgr",
  component: Layout,
  redirect: "/terminal-mgr/terminal-mgr",
  meta: {
    icon: "terminal",
    title: $t("menus.terminalMgr"),
    rank: 6,
    authority: ["terminal"]
  },
  children: [
    {
      path: "/terminal-mgr/terminal-mgr",
      name: "TerminalMgr",
      component: () => import("/@/views/terminal-mgr/index.vue"),
      meta: {
        title: $t("menus.terminalMgr"),
        keepAlive: true,
        showLink: true,
        authority: ["terminal"]
      }
    },
    {
      path: "/terminal-mgr/terminal-mgr-add",
      name: "TerminalMgrAdd",
      component: () => import("/@/views/terminal-mgr/add.vue"),
      meta: {
        title: $t("New"),
        keepAlive: true,
        showLink: false,
        authority: ["terminal", "terminal.add"]
      }
    },
    {
      path: "/terminal-mgr/terminal-mgr-detail/:terminalId",
      name: "TerminalMgrDetail",
      component: () => import("/@/views/terminal-mgr/detail.vue"),
      meta: {
        title: $t("Terminal Details"),
        keepAlive: true,
        showLink: false,
        authority: ["terminal", "terminal.detail"]
      }
    }
  ]
};

export default terminalMgrRouter;
