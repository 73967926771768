import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const equipRegisterRouter = {
  path: "/equip-register",
  name: "equipRegister",
  component: Layout,
  redirect: "/equip-register/index",
  meta: {
    icon: "equip-register",
    title: $t("menus.equipRegister"),
    rank: 3,
    authority: ["serial"]
  },
  children: [
    {
      path: "/equip-register/index",
      name: "EquipRegister",
      component: () => import("/@/views/equip-register/index.vue"),
      meta: {
        title: $t("menus.equipRegister"),
        keepAlive: true,
        showLink: true,
        authority: ["serial"]
      }
    }
  ]
};

export default equipRegisterRouter;
