import moment from "moment-timezone";

let offset = sessionStorage.getItem("offset");

export function convertTime(serviceDateTimeZone) {
  if (!serviceDateTimeZone) return "/";
  if (serviceDateTimeZone.indexOf("T") == -1) return serviceDateTimeZone;
  let offset = sessionStorage.getItem("offset");
  let timeZoneNow = sessionStorage.getItem("timeZone");
  const date = new Date(serviceDateTimeZone);
  let timeZone =
    timeZoneNow || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newDate = moment.tz(date, timeZone);
  return `${newDate.format("YYYY-MM-DD HH:mm:ss")}`;
}

export function convertSubmitTime(submitTime) {
  let offsetNew = sessionStorage.getItem("offset");
  // console.log("offsetNew", offsetNew);
  if (!submitTime) return "";
  if (submitTime.length === 19) {
    submitTime = submitTime.replace(" ", "T");
    submitTime += offsetNew;
  }
  return submitTime;
}
