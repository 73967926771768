import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const fileRouter = {
  path: "/file",
  component: Layout,
  redirect: "/file/admin",
  meta: {
    icon: "file-admin",
    title: $t("file.fileTitle"),
    rank: 33,
    authority: ["export"]
  },
  children: [
    {
      path: "/file/admin",
      name: "fileTitle",
      component: () => import("/@/views/file-admin/index.vue"),
      meta: {
        title: $t("file.fileExport"),
        showParent: true,
        authority: ["export.page1"]
      }
    },
    {
      path: "/file/import",
      name: "fileImport",
      component: () => import("/@/views/file-admin/import.vue"),
      meta: {
        title: $t("file.fileImport"),
        showParent: true,
        authority: ["export.page"]
      }
    }
  ]
};

export default fileRouter;
