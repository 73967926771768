import { defineStore } from "pinia";
import { store } from "/@/store";

type merchant = {
  id?: String;
  cid?: String;
  pname?: String;
  time1?: String;
  time2?: String;
};

export const useMerchant = defineStore({
  id: "vue3-admin-merchant",
  state: (): merchant => ({
    id: "",
    cid: "",
    pname: "",
    time1: "",
    time2: ""
  }),
  actions: {
    SET_CID(cid) {
      this.cid = cid;
    },
    SET_ID(id) {
      this.id = id;
    },
    SET_PNAME(pname) {
      this.pname = pname;
    },
    SET_Time1(item) {
      this.time1 = item;
    },
    SET_ClearTime1() {
      clearInterval(this.time1);
    },
    SET_Time2(item) {
      this.time2 = item;
    },
    SET_ClearTime2() {
      clearInterval(this.time2);
    }
  }
});

export function useMerchantHook() {
  return useMerchant(store);
}
