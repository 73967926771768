import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const merchantRouter = {
  path: "/merchant",
  component: Layout,
  redirect: "/merchant/merchantadmin",
  meta: {
    icon: "merchant-admin",
    title: $t("menus.merchantTitle"),
    rank: 4,
    keepAlive: true,
    authority: ["merchant"]
  },
  children: [
    {
      path: "/merchant/merchantadmin",
      name: "merchantInfor",
      component: () =>
        import("/@/views/merchant-admin/MerchantAdmin/index.vue"),
      meta: {
        title: $t("menus.merchantTitle"),
        keepAlive: true,
        authority: ["merchant"]
      }
    },
    {
      path: "/merchant/merchantrecycle",
      name: "merchantRecycle",
      component: () => import("/@/views/merchant-admin/RecycleMID/index.vue"),
      meta: {
        title: $t("menus.merchantRecycle"),
        showLink: false,
        keepAlive: true,
        authority: ["merchant"]
      }
    },
    {
      path: "/merchant/merchantopera",
      name: "merchantOpera",
      component: () =>
        import("/@/views/merchant-admin/MerchantOpera/index.vue"),
      meta: {
        title: $t("merchant.merchantOpera"),
        showLink: false,
        keepAlive: true,
        authority: ["merchant"]
      }
    },
    {
      path: "/merchant/merchantadd",
      name: "merchantAdd",
      component: () => import("/@/views/merchant-admin/MerchantAdd/index.vue"),
      meta: {
        title: $t("merchant.merchantAdd"),
        showLink: false,
        keepAlive: true,
        authority: ["merchant"]
      }
    },
    {
      path: "/merchant/edit1",
      name: "merchantEdit1",
      component: () => import("/@/views/merchant-admin/MerchantEdit/index.vue"),
      meta: {
        title: $t("merchant.merchantDetails"),
        showLink: false,
        keepAlive: true,
        authority: ["merchant"]
      }
    },
    {
      path: "/merchant/edit",
      name: "merchantEdit",
      component: () => import("/@/views/merchant-admin/MerchantEdit/index.vue"),
      meta: {
        title: $t("merchant.merchantEdit"),
        showLink: false,
        keepAlive: true,
        authority: ["merchant"]
      }
    },
    {
      path: "/merchant/addTerminal",
      name: "merchantTerminal",
      component: () =>
        import("/@/views/merchant-admin/MerchantAddTerminal/index.vue"),
      meta: {
        title: $t("menus.terminalMgrAdd"),
        showLink: false,
        keepAlive: true,
        authority: ["merchant"]
      }
    }
  ]
};

export default merchantRouter;
