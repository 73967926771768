import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const audioRouter = {
  path: "/audit-admin",
  component: Layout,
  redirect: "/audit-admin/audit-admin",
  meta: {
    icon: "audit-admin",
    title: $t("menus.auditAdmin"),
    rank: 5,
    authority: ["audit"]
  },
  children: [
    {
      path: "/audit-admin/audit-admin",
      name: "auditAdmin",
      component: () => import("/@/views/audit-admin/AuditAdmin/index.vue"),
      meta: {
        title: $t("menus.auditAdmin"),
        authority: ["audit"]
      }
    },
    {
      path: "/audit-admin/audit-detail",
      name: "auditDetail",
      component: () => import("/@/views/audit-admin/AuditDetail/index.vue"),
      meta: {
        title: $t("Review Details"),
        showLink: false,
        authority: ["audit"]
      }
    }
  ]
};

export default audioRouter;
