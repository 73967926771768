import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const systemRouter = {
  path: "/system",
  component: Layout,
  redirect: "/system/index",
  meta: {
    icon: "system-setting",
    title: $t("systemSetting.pageTitle"),
    rank: 12,
    authority: ["recovery"]
  },
  children: [
    {
      path: "/system/index",
      name: "SystemSetting",
      component: () => import("/@/views/system/index.vue"),
      meta: {
        title: $t("systemSetting.pageTitle"),
        authority: ["recovery"]
      }
    }
  ]
};

export default systemRouter;
