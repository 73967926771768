import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

// 注意：数据看板后端没有权限控制，后端返回code为空也会展示该菜单
// hasAuth：前端控制该路由是否显示;
// authority：根据后端返回code控制是否显示路由。
const homeRouter = {
  path: "/",
  name: "home",
  component: Layout,
  redirect: "/data-board",
  meta: {
    icon: "data-board",
    title: $t("menus.hshome"),
    rank: 0,
    hasAuth: true
  },
  children: [
    {
      path: "/data-board",
      name: "DataBoard",
      component: () => import("/@/views/data-board/index.vue"),
      meta: {
        title: $t("menus.hshome"),
        hasAuth: true
      }
    }
  ]
};

export default homeRouter;
