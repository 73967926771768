import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const dataReport = {
  path: "/data-report",
  component: Layout,
  redirect: "/data-report/business-report",
  meta: {
    icon: "data-report",
    title: $t("menus.dataReport"),
    rank: 1,
    authority: [
      "reportoper",
      "reportrank",
      "trans.analysis",
      "currency.analysis"
    ]
  },
  children: [
    {
      path: "/data-report/business-report",
      name: "BusinessReport",
      component: () => import("/@/views/data-report/BusinessReport/index.vue"),
      meta: {
        title: $t("menus.businessReport"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["reportoper"]
      }
    },
    {
      path: "/data-report/ranking-list",
      name: "RankingList",
      component: () => import("/@/views/data-report/RankingList/index.vue"),
      meta: {
        title: $t("menus.rankingList"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["reportrank"]
      }
    },
    {
      path: "/data-report/transaction-analysis",
      name: "TransactionAnalysis",
      component: () =>
        import("/@/views/data-report/TransactionAnalysis/index.vue"),
      meta: {
        title: $t("menus.transactionAnalysis"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["trans.analysis"]
      }
    },
    {
      path: "/data-report/currency-analysis",
      name: "CurrencyAnalysis",
      component: () =>
        import("/@/views/data-report/CurrencyAnalysis/index.vue"),
      meta: {
        title: $t("menus.currencyAnalysis"),
        keepAlive: true,
        showLink: true,
        showParent: true,
        authority: ["currency.analysis"]
      }
    }
  ]
};

export default dataReport;
