import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const accountRouter = {
  path: "/account-mgr",
  component: Layout,
  redirect: "/account-mgr/recon-mgr",
  meta: {
    icon: "account-treatment",
    title: $t("menus.accountMgr"),
    rank: 8,
    keepAlive: true,
    authority: [
      "checkmanage",
      "disputeinfo",
      "checkresult",
      "checkdata",
      "checkfile",
      "disputeaudit"
    ]
  },
  children: [
    {
      path: "/account-mgr/recon-mgr",
      name: "ReconMgr",
      component: () => import("/@/views/account-mgr/recon-mgr/index.vue"),
      meta: {
        title: $t("menus.reconMgr"),
        showParent: true,
        keepAlive: true,
        authority: ["checkmanage"]
      },
      children: [
        {
          path: "/account-mgr/recon-mgr/detail",
          name: "ReconMgrDetail",
          component: () => import("/@/views/account-mgr/recon-mgr/detail.vue"),
          meta: {
            title: $t("menus.reconResultDetail"),
            showLink: false,
            showParent: true,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/account-mgr/recon-result",
      name: "ReconResult",
      component: () => import("/@/views/account-mgr/recon-result/index.vue"),
      meta: {
        title: $t("menus.reconResult"),
        showParent: true,
        keepAlive: true,
        authority: ["checkresult"]
      },
      children: [
        {
          path: "/account-mgr/recon-result/detail",
          name: "ReconResultDetail",
          component: () => import("/@/views/account-mgr/recon-result/detail.vue"),
          meta: {
            title: $t("menus.reconDetail"),
            showLink: false,
            showParent: true,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/account-mgr/recon-data",
      name: "ReconData",
      component: () => import("/@/views/account-mgr/recon-data/index.vue"),
      meta: {
        title: $t("menus.reconData"),
        showParent: true,
        keepAlive: true,
        authority: ["checkdata"]
      }
    },
    {
      path: "/account-mgr/recon-file",
      name: "ReconFile",
      component: () => import("/@/views/account-mgr/recon-file/index.vue"),
      meta: {
        title: $t("menus.reconFile"),
        showParent: true,
        keepAlive: true,
        authority: ["checkfile"]
      }
    },
    {
      path: "/account-mgr/error-handle",
      name: "ErrorHandle",
      component: () => import("/@/views/account-mgr/error-handle/index.vue"),
      meta: {
        title: $t("menus.errorMgr"),
        showParent: true,
        keepAlive: true,
        authority: ["disputeinfo"]
      },
      children: [
        {
          path: "/account-mgr/error-handle/detail",
          name: "errorHandleDetail",
          component: () => import("/@/views/account-mgr/error-handle/detail.vue"),
          meta: {
            title: $t("menus.errorHandleDetail"),
            showLink: false,
            showParent: true,
            keepAlive: true
          }
        }
      ]
    },
    {
      path: "/account-mgr/errorAudit",
      name: "ErrorReview",
      component: () => import("/@/views/error-review/index.vue"),
      meta: {
        title: $t("menus.erroReview"),
        showParent: true,
        keepAlive: true,
        authority: ["disputeaudit"]
      },
      children: [
        {
          path: "/account-mgr/auditProcess", // 差错审核过程
          name: "auditProcess",
          component: () => import("/@/views/error-review/auditProcess.vue"),
          meta: {
            title: "审核过程",
            showLink: false,
            showParent: true,
            keepAlive: true
          }
        },
        {
          path: "/account-mgr/auditDetails", // 差错审核详情
          name: "auditDetails",
          component: () => import("/@/views/error-review/auditDetails.vue"),
          meta: {
            title: "审核详情",
            showLink: false,
            showParent: true,
            keepAlive: true
          }
        }
      ]
    }
  ]
};

export default accountRouter;
