import { defineStore } from "pinia";
import { store } from "/@/store";
import { cacheType } from "./types";
import { constantMenus } from "/@/router";
import { cloneDeep } from "lodash-unified";
import { RouteConfigs } from "/@/layout/types";
import { ascending, filterTree } from "/@/router/utils";
import { router } from "/@/router";
import { storageSession } from "/@/utils/storage";

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.hasAuth) {
    return true;
  }
  if (route.meta && route.meta.authority) {
    return roles.some(role => route.meta.authority.includes(role));
  } else {
    return false;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach(route => {
    const tmp = { ...route };
    // console.log(`${tmp.path}: ${hasPermission(roles, tmp)}`);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      if (tmp.redirect && tmp.children && tmp.children.length > 0) {
        tmp.redirect = tmp.children[0].path;
      }
      res.push(tmp);
    }
  });

  return res;
}

export const usePermissionStore = defineStore({
  id: "klond-permission",
  state: () => ({
    // 静态路由生成的菜单
    constantMenus,
    // 整体路由生成的菜单（静态、动态）
    wholeMenus: [],
    // 深拷贝一个菜单树，与导航菜单不突出
    menusTree: [],
    buttonAuth: storageSession.getItem("info")?.roles ?? [],
    // 缓存页面keepAlive
    cachePageList: [],

    routes: [],
    // 权限控制代码
    roles: storageSession.getItem("info")?.roles ?? []
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["roles"],
        key: "roles"
      }
    ]
  },
  actions: {
    setRoutes(routes) {
      this.routes = constantMenus.concat(routes);
    },
    setRoles(roles) {
      this.roles = roles;
      this.buttonAuth = roles;
    },
    initRouter(roles?) {
      if (roles) {
        this.setRoles(roles);
        // 解决权限更新菜单不更新问题
        this.wholeMenus = [];
      }
      return new Promise(resolve => {
        if (this.wholeMenus.length > 0) return;
        this.wholeMenus = filterTree(ascending(this.constantMenus));
        // console.log("=== initRouter current roles: ", this.roles);
        const accessedRoutes = filterAsyncRoutes(this.wholeMenus, this.roles);
        this.wholeMenus = accessedRoutes;
        router.addRoute({
          path: "/:pathMatch(.*)",
          redirect: "/error/404"
        });

        this.menusTree = cloneDeep(
          filterTree(ascending(this.constantMenus.concat(accessedRoutes)))
        );

        const getButtonAuth = (arrRoutes: Array<RouteConfigs>) => {
          if (!arrRoutes || !arrRoutes.length) return;
          arrRoutes.forEach((v: RouteConfigs) => {
            if (v.meta && v.meta.authority) {
              this.buttonAuth.push(...v.meta.authority);
            }
            if (v.children) {
              getButtonAuth(v.children);
            }
          });
        };

        getButtonAuth(this.wholeMenus);

        // this.changeSetting(accessedRoutes);

        resolve(router);
      });
    },
    // 获取异步路由菜单
    asyncActionRoutes(routes) {
      if (this.wholeMenus.length > 0) return;
      this.wholeMenus = filterTree(
        ascending(this.constantMenus.concat(routes))
      );

      this.menusTree = cloneDeep(
        filterTree(ascending(this.constantMenus.concat(routes)))
      );

      const getButtonAuth = (arrRoutes: Array<RouteConfigs>) => {
        if (!arrRoutes || !arrRoutes.length) return;
        arrRoutes.forEach((v: RouteConfigs) => {
          if (v.meta && v.meta.authority) {
            this.buttonAuth.push(...v.meta.authority);
          }
          if (v.children) {
            getButtonAuth(v.children);
          }
        });
      };

      getButtonAuth(this.wholeMenus);
    },
    async changeSetting(routes) {
      await this.asyncActionRoutes(routes);
    },
    cacheOperate({ mode, name }: cacheType) {
      switch (mode) {
        case "add":
          this.cachePageList.push(name);
          this.cachePageList = [...new Set(this.cachePageList)];
          break;
        case "delete":
          // eslint-disable-next-line no-case-declarations
          const delIndex = this.cachePageList.findIndex(v => v === name);
          delIndex !== -1 && this.cachePageList.splice(delIndex, 1);
          break;
      }
    },
    // 清空缓存页面
    clearAllCachePage() {
      this.cachePageList = [];
    }
  }
});

export function usePermissionStoreHook() {
  return usePermissionStore(store);
}
