import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const logRouter = {
  path: "/log",
  component: Layout,
  redirect: "/log/operation",
  meta: {
    icon: "log-admin",
    title: $t("log.pageTitle"),
    rank: 9,
    authority: ["operlog"]
  },
  children: [
    {
      path: "/log/operation",
      name: "OperationLog",
      component: () => import("/@/views/log/operation/index.vue"),
      meta: {
        title: $t("log.operationLog"),
        authority: ["operlog"],
        showParent: true,
        keepAlive: true
      }
    }
    // {
    //   path: "/log/transaction",
    //   name: "TransactionLog",
    //   component: () => import("/@/views/log/transaction/index.vue"),
    //   meta: {
    //     title: $t("log.transactionLog"),
    //     authority: ["translog"],
    //     showParent: true
    //   }
    // }
  ]
};

export default logRouter;
