import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const transactionFlowRouter = {
  path: "/transaction-flow",
  component: Layout,
  redirect: "/transaction-flow/index",
  meta: {
    icon: "transaction-flow",
    title: $t("交易管理"),
    rank: 7,
    authority: ["trans", "translog", "acquirer.refund", "acquirer.refundAudit"]
  },
  children: [
    {
      path: "/transaction-flow/index",
      name: "TransactionFlow",
      component: () => import("/@/views/transaction-flow/index.vue"),
      meta: {
        title: $t("transactionFlow.pageTitle"),
        showParent: true,
        authority: ["trans"]
      }
    },
    {
      path: "/log/transaction",
      name: "TransactionLog",
      component: () => import("/@/views/log/transaction/index.vue"),
      meta: {
        title: $t("log.transactionLog"),
        authority: ["translog"],
        showParent: true
      }
    },
    {
      path: "/transaction-flow/ret-record",
      name: "retRecord",
      component: () => import("/@/views/ret-record/index.vue"),
      meta: {
        title: $t("退货记录"),
        showParent: true,
        authority: ["acquirer.refund"]
        // authority: ["refund"]
      },
      children: [
        {
          path: "/transaction-flow/ret-record/detail",
          name: "retRecordDetail",
          component: () => import("/@/views/ret-record/detail.vue"),
          meta: {
            title: $t("详情"),
            showParent: true,
            showLink: false
            // roles: ["dop.home"]
          }
        }
      ]
    },
    {
      path: "/transaction-flow/ret-audit",
      name: "retAudit",
      component: () => import("/@/views/ret-audit/AuditAdmin/index.vue"),
      meta: {
        title: $t("退货审核"),
        showParent: true,
        authority: ["acquirer.refundAudit"]
        // authority: ["refundAudit"]
      }
      // children: [
      //   {
      //     path: "/transaction-flow/ret-audit/detail",
      //     name: "retAuditDetail",
      //     component: () => import("/@/views/ret-audit/AuditDetail/detail.vue"),
      //     meta: {
      //       title: $t("详情"),
      //       showParent: true,
      //       showLink: false
      //       // roles: ["dop.home"]
      //     }
      //   }
      // ]
    }
  ]
};

export default transactionFlowRouter;
